// client errors codes
import { SpecterProducts, ZapierActionType } from "@prisma/client"

//
// General
//
export enum ResponseCode {
  NOT_FOUND = "NOT_FOUND",
  NO_BODY = "NO_BODY",
  API_KEY_MISSING = "API_KEY_MISSING",
  API_KEY_NOT_VALID = "API_KEY_NOT_VALID",
  DOMAIN_NOT_REACHABLE = "DOMAIN_NOT_REACHABLE",
  INVESTOR_NOT_FOUND = "INVESTOR_NOT_FOUND",
  MISSING_DOMAIN = "MISSING_DOMAIN",
  MISSING_DOMAIN_KNOWN_NOT_READY = "MISSING_DOMAIN_KNOWN_NOT_READY",
  MISSING_DOMAIN_KNOWN_NOT_READY_OUTDATED = "MISSING_DOMAIN_KNOWN_NOT_READY_OUTDATED",
  NOT_ADMIN = "NOT_ADMIN",
  NOT_PERMITTED = "NOT_PERMITTED",
  NOT_SPECTER_PRODUCT = "NOT_SPECTER_PRODUCT",
  NOT_SPECTER_PRODUCT_PROPERTY = "NOT_SPECTER_PRODUCT_PROPERTY",
  NO_COMPANIES_FOUND = "NO_COMPANIES_FOUND",
  NO_COMPANIES_WITH_INVESTOR = "NO_COMPANIES_WITH_INVESTOR",
  NO_COMPANIES_WITH_LINKEDIN_URL = "NO_COMPANIES_WITH_LINKEDIN_URL",
  NO_MORE_TOKENS = "NO_MORE_TOKENS",
  PAGE_OUT_OF_BOUNDS = "PAGE_OUT_OF_BOUNDS",
  PAGE_NOT_FOUND = "PAGE_NOT_FOUND",
  RATE_LIMITED = "RATE_LIMITED",
  OUT_OF_CREDITS = "OUT_OF_CREDITS",
  SUCCESS = "SUCCESS",
  ZAPIER_NO_ENTITLEMENT = "ZAPIER_NO_ENTITLEMENT",
  ZAPIER_NO_NEW_SIGNALS = "ZAPIER_NO_NEW_SIGNALS",
  ZAPIER_NO_SUBSCRIBED_USERS = "ZAPIER_NO_SUBSCRIBED_USERS",
  ZAPIER_NO_TALENT_SIGNALS_FOUND = "ZAPIER_NO_TALENT_SIGNALS_FOUND",
  ZAPIER_NO_TALENT_SIGNALS_FOUND_THIS_WEEK = "ZAPIER_NO_TALENT_SIGNALS_FOUND_THIS_WEEK",
  ZAPIER_NO_STRATEGIC_INTELLIGENCE_FOUND = "ZAPIER_NO_STRATEGIC_INTELLIGENCE_FOUND",
  ZAPIER_NO_STRATEGIC_INTELLIGENCE_FOUND_THIS_WEEK = "ZAPIER_NO_STRATEGIC_INTELLIGENCE_FOUND_THIS_WEEK",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  INVALID_LIST_UUID = "INVALID_LIST_UUID",
  LIST_ALREADY_EXISTS = "LIST_ALREADY_EXISTS",
  NO_TEAM_ADMIN = "NO_TEAM_ADMIN",
  BAD_SEARCH_ID_TYPE = "BAD_SEARCH_ID_TYPE",
  NOT_SUPPORTED_PRODUCT = "NOT_SUPPORTED_PRODUCT",
}

// Unauthenticated
export const API_KEY_MISSING = {
  errorCode: ResponseCode.API_KEY_MISSING,
  message: "No API Key was presented on the header X-API-KEY",
}
export const API_KEY_NOT_VALID = {
  errorCode: ResponseCode.API_KEY_NOT_VALID,
  message: "API key present, but not valid",
}

// Forbidden
export const NOT_ADMIN = {
  errorCode: ResponseCode.NOT_ADMIN,
  message: "The user is not an admin",
}

export const NOT_PERMITTED = {
  errorCode: ResponseCode.NOT_PERMITTED,
  message: "API key present, but you are not permitted",
}

// General Not Found
export const NOT_FOUND = {
  errorCode: ResponseCode.NOT_FOUND,
  message: "Not Found",
}

// Not Found
export const NO_COMPANIES_FOUND = {
  errorCode: ResponseCode.NO_COMPANIES_FOUND,
  message: "No companies found",
}

// Unprocessable
export const PAGE_OUT_OF_BOUNDS = (page: number, min: number, max: number) => ({
  errorCode: ResponseCode.PAGE_OUT_OF_BOUNDS,
  message: "The page you've requested is out of bounds",
  context: {
    page,
    min,
    max,
  },
})

export const DOMAIN_NOT_REACHABLE = (domain: string) => ({
  errorCode: ResponseCode.DOMAIN_NOT_REACHABLE,
  message: `An attempt was made to ping ${domain}, but there was no reply, please check the domain`,
  context: {
    domain,
  },
})

export const NOT_SUPPORTED_PRODUCT = (product: string, supported: string) => ({
  errorCode: ResponseCode.NOT_SUPPORTED_PRODUCT,
  message: `The product "${product}" is not support by this operation. Only "${supported}" are supported.`,
  context: {
    product,
    supported,
  },
})

export const NOT_SPECTER_PRODUCT = (product: string) => ({
  errorCode: ResponseCode.NOT_SPECTER_PRODUCT,
  message: `${product} is not a specter product`,
  context: {
    product,
    specterProducts: Object.keys(SpecterProducts),
  },
})

export const NO_BODY = {
  errorCode: ResponseCode.NO_BODY,
  message: "The body needs to be specified to complete the action",
}

export const NOT_SPECTER_PRODUCT_PROPERTY = (
  product: SpecterProducts,
  property: string
) => ({
  errorCode: ResponseCode.NOT_SPECTER_PRODUCT_PROPERTY,
  message: `${product} is not a specter product`,
  context: {
    product,
    specterProducts: Object.keys(SpecterProducts),
    property,
  },
})

// TOO MANY REQUESTS
export const RATE_LIMITED = {
  errorCode: ResponseCode.RATE_LIMITED,
  message: "You have been rate-limited",
}

export const OUT_OF_CREDITS = {
  errorCode: ResponseCode.OUT_OF_CREDITS,
  message: "There are no more credits available to complete the request",
}

export const NO_MORE_TOKENS = {
  errorCode: ResponseCode.NO_MORE_TOKENS,
  message:
    "You have no more API tokens, please contact team@tryspecter.com to arrange for them to be refilled",
}

//
// Zapier
//
export const ZAPIER_NO_SUBSCRIBED_USERS = (action: ZapierActionType) => ({
  errorCode: ResponseCode.ZAPIER_NO_SUBSCRIBED_USERS,
  message: `There are subscribed users to ${action}, but no users require that we push the ZapierActionType.new_talent_signal event`,
  context: {
    action,
  },
})

export const ZAPIER_NO_ENTITLEMENT = (action: ZapierActionType) => ({
  errorCode: ResponseCode.ZAPIER_NO_ENTITLEMENT,
  message: `Users are subscribed to ${action}, but no users have entitlement to receive it`,
  context: {
    action,
  },
})

export const ZAPIER_NO_NEW_SIGNALS = (action: ZapierActionType) => ({
  errorCode: ResponseCode.ZAPIER_NO_NEW_SIGNALS,
  message: `There are no signals to send for ${action}`,
  context: {
    action,
  },
})

export const ZAPIER_NO_TALENT_SIGNALS_FOUND = (action: ZapierActionType) => ({
  errorCode: ResponseCode.ZAPIER_NO_TALENT_SIGNALS_FOUND,
  message: `No talent signals could be found in the database to send for ${action}`,
  context: {
    action,
  },
})

export const ZAPIER_NO_TALENT_SIGNALS_FOUND_THIS_WEEK = (
  action: ZapierActionType
) => ({
  errorCode: ResponseCode.ZAPIER_NO_TALENT_SIGNALS_FOUND_THIS_WEEK,
  message: `No talent signals could be found this week ${action}`,
  context: {
    action,
  },
})

export const ZAPIER_NO_STRATEGIC_INTELLIGENCE_FOUND = (
  action: ZapierActionType
) => ({
  errorCode: ResponseCode.ZAPIER_NO_STRATEGIC_INTELLIGENCE_FOUND,
  message: `No strategic intelligence signals could be found in the database to send for ${action}`,
  context: {
    action,
  },
})

export const ZAPIER_NO_STRATEGIC_INTELLIGENCE_FOUND_THIS_WEEK = (
  action: ZapierActionType
) => ({
  errorCode: ResponseCode.ZAPIER_NO_STRATEGIC_INTELLIGENCE_FOUND_THIS_WEEK,
  message: `No strategic intelligence signals could be found this week ${action}`,
  context: {
    action,
  },
})
